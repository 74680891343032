import { NgModule } from '@angular/core';
import { AntdModule } from '@shared/antd/antd.module';
import { SharedModule } from '@shared/shared.module';
import { PaginationComponent } from './pagination.component';

@NgModule({
  declarations: [PaginationComponent],
  imports: [SharedModule, AntdModule],
  exports: [PaginationComponent],
})
export class PaginationModule {}
