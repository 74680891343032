import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import _ from 'lodash';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  template: `
    <div class="p-1 mt-2" *ngIf="total">
      <div class="row">
        <nav class="pagination-nav" aria-label="Page Size Selector">
          <nz-button-group *ngIf="showSizeChanger">
            <button 
              nz-button
              *ngFor="let num of pageSizeOptions"
              [nzType]="num===pageSize?'primary':'default'"
              (click)="changePageSize($event,num)"
              title="Page Size {{num}}"
            ><span class="btn-hidden btn-text">Page Size </span><span>{{num}}</span></button>
          </nz-button-group>
        </nav>
        <nav class="pagination-nav" aria-label="Page Navigator">
          <span *ngIf="showTotal" class="mr-2" >Page {{pageIndex}} of {{maxPage.length}} ({{total}} items)</span> 
          <nz-button-group>
            <button 
              nz-button
              title="Previous"
              aria-label="Previous"
              tabindex="{{pageIndex===1?-1:0}}"
              [disabled]="pageIndex===1"
              (click)="changePage($event,'p')" 
              title="Prev Page"
            >< <span class="btn-hidden btn-text">Page </span></button>
            <button 
              *ngFor="let num of maxPage"
              title="Page {{num}}"
              aria-label="Page {{num}}"
              aria-pressed="{{num===pageSize}}"
              nz-button
              [nzType]="num===pageIndex?'primary':'default'"
              (click)="changePage($event,num)" 
              title="Page {{num}}"
            ><span class="btn-hidden btn-text">Page </span>{{num}}</button>
            <button 
              nz-button
              title="Next"
              aria-label="Next"
              tabindex="{{pageIndex===maxPage.length?-1:0}}"
              [disabled]="pageIndex===maxPage.length"
              (click)="changePage($event,'n')" 
              title="Next Page"
            >><span class="btn-hidden btn-text">Page </span></button>
          </nz-button-group>
        </nav>
      </div>
    </div>
    
  `,
  styleUrls: ['./pagination.component.less'],
})
export class PaginationComponent
  implements OnInit, OnDestroy, OnChanges {

  constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) { }
  @Input() showSizeChanger: boolean = true;
  @Input() showTotal: boolean = true;
  @Input() total = 0;
  @Input() pageSize = 50;
  @Input() pageIndex = 1;
  @Input() pageSizeOptions: number[] = [50, 100, 200];
  @Output() readonly pageSizeChange = new EventEmitter<number>();
  @Output() readonly pageIndexChange = new EventEmitter<number>();

  maxPage: number[] = [1];
  isPhone: boolean;
  destroy$ = new Subject();


  ngOnInit(): void {
    this.resize();
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe(() => {
        this.resize();
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.total || changes.showPagination) {
      this.checkPage()
    }
  }

  resize() {
    if (window.innerWidth > 768) {
      this.isPhone = false
    } else {
      this.isPhone = true
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  initPagination() {
    this.pageIndex = 1;
    this.pageIndexChange.next(this.pageIndex);
  }

  changePage(e, number) {
    e.preventDefault();
    e.stopPropagation();
    switch (number) {
      case 'p':
        if (this.pageIndex > 1)
          this.pageIndex--; break;
      case 'n':
        if (this.pageIndex < this.maxPage.length)
          this.pageIndex++; break;
      case 'f':
        if (this.pageIndex > 1)
          this.pageIndex = 1; break;
      case 'l':
        if (this.pageIndex < this.maxPage.length)
          this.pageIndex = this.maxPage.length; break;
      default:
        this.pageIndex = number
    }
    this.pageIndexChange.next(this.pageIndex);
  }

  changePageSize(e, num) {
    e.preventDefault();
    e.stopPropagation();
    // const index = Math.ceil(this.pageIndex * this.pageSize / num)
    this.pageSize = num
    this.pageSizeChange.next(this.pageSize);
    this.checkPage()
    // this.pageIndex = _.min([index, this.maxPage.length])
  }

  checkPage() {
    if (this.total && this.pageSize) {
      const length = Math.ceil(this.total / this.pageSize)
      this.maxPage = Array.from({ length }, (v, k) => k + 1);
    } else {
      this.maxPage = [1]
    }
    if (!this.pageIndex) this.pageIndex = 1
    this.pageIndex = _.min([this.pageIndex, this.maxPage.length])
    this.pageIndexChange.next(this.pageIndex);
  }
}
